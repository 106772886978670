/* iPhone Safari Fixes - Ensure Full-Screen Display */
html, body {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    -webkit-text-size-adjust: 100%;
    touch-action: manipulation;
    height: 100vh;
    height: -webkit-fill-available;
}

body {
    position: fixed;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    overflow: hidden;
    width: 100vw;
}

/* Fix for Safari Browser Address Bar */
.cdk-overlay-container {
    position: fixed !important;
    height: 100vh !important;
    width: 100vw !important;
    top: 0;
    left: 0;
    z-index: 1000;
}

/* Apply adjustments when scrolling */
body.scrolled {
    height: 100vh !important;
    overflow: hidden !important;
}

// -----------------------------------------------------------------------------------------------------
// @ variables
// -----------------------------------------------------------------------------------------------------
// theme headers 
$carded-header-height: 135px;
$carded-toolbar-height: 65px;
$header-height: 85px;
$fa-font-path: "@fortawesome/fontawesome-free/webfonts";
// Import Fuse core library
@import "@fuse/scss/core";

// Import app.theme.scss
@import "app/app.theme";

@import 'ngx-toastr/toastr';
@import './../node_modules/ngx-lightbox/lightbox.css';

@import "codemirror/lib/codemirror";
@import "codemirror/theme/material";

@import './../node_modules/@fortawesome/fontawesome-free/css/all.css';
// @import '~@fortawesome/fontawesome-free/scss/fontawesome';
// @import '~@fortawesome/fontawesome-free/scss/brands';
// @import '~@fortawesome/fontawesome-free/scss/solid';
// @import '~@fortawesome/fontawesome-free/scss/regular';
// Import Common/Global Styles
@import "app/styles/app.common";
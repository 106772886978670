// -----------------------------------------------------------------------------------------------------
@use '@angular/material' as mat;
// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------

//defining the color palette
$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

$fuse-white: (500: white, contrast: (500: $dark-primary-text));
$fuse-black: (500: black, contrast: (500: $light-primary-text, ));
$fuse-navy: (50: #ECECEE, 100: #C5C6CB, 200: #9EA1A9, 300: #7D818C, 400: #5C616F, 500: #3C4252, 600: #353A48, 700: #2D323E, 800: #262933, 900: #1E2129, A100: #C5C6CB, A200: #9EA1A9, A400: #5C616F, A700: #2D323E, contrast: (50: $dark-primary-text, 100: $dark-primary-text, 200: $dark-primary-text, 300: $light-primary-text, 400: $light-primary-text, 500: $light-primary-text, 600: $light-primary-text, 700: $light-primary-text, 800: $light-primary-text, 900: $light-primary-text, A100: $dark-primary-text, A200: $light-primary-text, A400: $light-primary-text, A700: $light-primary-text, ));
$fuse-red: (50: #ECECEE, 100: #C5C6CB, 200: #9EA1A9, 300: #7D818C, 400: #5C616F, 500: #3C4252, 600: #353A48, 700: #2D323E, 800: #262933, 900: #1E2129, A100: #C5C6CB, A200: #9EA1A9, A400: #5C616F, A700: #2D323E, contrast: (50: $dark-primary-text, 100: $dark-primary-text, 200: $dark-primary-text, 300: $light-primary-text, 400: $light-primary-text, 500: $light-primary-text, 600: $light-primary-text, 700: $light-primary-text, 800: $light-primary-text, 900: $light-primary-text, A100: $dark-primary-text, A200: $light-primary-text, A400: $light-primary-text, A700: $light-primary-text, ));
// Generate Fuse color classes for custom palettes
$custom_palettes: (fuse-white: $fuse-white, fuse-black: $fuse-black, fuse-navy: $fuse-navy);
@include fuse-color-classes($custom_palettes);
// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------
// Angular Material typography
$typography: mat.define-typography-config($font-family: 'Muli, Helvetica Neue, Arial, sans-serif', $headline-6: mat.define-typography-level(20px, 32px, 600), $body-2: mat.define-typography-level(14px, 24px, 600), $button: mat.define-typography-level(14px, 14px, 600)
);
// Setup the typography
@include mat.all-component-typographies($typography);
// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------
// Import app and page component themes here to enable theming for them
@import "src/app/layout/components/chat-panel/chat-panel.theme";
@import "src/app/layout/components/toolbar/toolbar.theme";
@import "src/app/main/dashboard/calendar/calendar.theme";
@import "src/app/main/dashboard/mail/mail.theme";
@import "src/app/main/dashboard/activities/activities.theme";
@import "src/app/main/authentication/login/login.theme";
@import "src/app/main/authentication/forgot-password/forgot-password.theme";
@import "src/app/main/authentication/new-password/new-password.theme";
@import "src/app/main/master-data/grid-boards/grid-boards.theme";
@import "src/app/components/time-line/time-line.theme";
@import "src/app/main/user-faqs/user-faqs.theme";
@import "src/app/main/marketplace/message-boards/layout/timeline/timeline.theme";
@import "src/app/main/marketplace/message-boards/layout/timeline/share-modal/share-modal-theme";
@import "src/app/main/marketplace/message-boards/layout/about-us/about-us.theme";
@import "src/app/main/marketplace/message-boards/layout/photos-videos/photos-videos.theme";
@import "src/app/layout/components/quick-panel/quick-panel.theme";
@import "src/app/layout/components/quick-panel/dialog/notification/notification.theme";
@import "src/app/main/authentication/agencies/create-agency/tabs/stripe-subscriptions/stripe-subscriptions.theme";

// Define a mixin for easier access
@mixin components-theme($theme) {
    // Layout components
    @include chat-panel-theme($theme);
    @include toolbar-theme($theme);
    @include login-theme($theme);
    @include forgot-password-theme($theme);
    @include new-password-theme($theme);
    @include scrumboard-theme($theme);
    @include quick-panel-theme($theme);
    @include notifications-theme($theme);
    //Apps
    @include calendar-theme($theme);
    @include mail-theme($theme);
    @include activities-theme($theme);
    @include time-line-theme($theme);
    @include user-faqs-theme($theme);
    @include timeline-theme($theme);
    @include about-us-theme($theme);
    @include photos-videos-theme($theme);
    @include share-modal-theme($theme);
    @include stripe-subscriptions-theme($theme);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    .header {
        .search-wrapper {
            background: map-get($background, card);
            .search {
                .mat-icon {
                    color: map-get($foreground, icon);
                }
                input {
                    background: map-get($background, card);
                    color: map-get($foreground, text);
                }
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette($fuse-navy);
$default-accent-palette: mat.define-palette(mat.$light-blue-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$red-palette);
// Create the Material theme object
$theme: mat.define-light-theme((
  color: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {
    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);
    // Apply the theme to the user components
    @include components-theme($theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $default-primary-palette, accent: $default-accent-palette, warn: $default-warn-palette);
    @include fuse-color-classes($palettes);

     input,
    textarea {
      color: black !important;
    }
    .mat-mdc-select-value-text {
      color: black !important;
    }
    .mat-mdc-form-field-label {
      color: black !important;
    }
    .bubble.time{
      color: black !important;
    }
}
// -----------------------------------------------------------------------------------------------------
// @ Define the light red theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette(mat.$red-palette, 200, 300, 400);
$default-accent-palette: mat.define-palette(mat.$red-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$red-palette);
// Create the Material theme object

$theme: mat.define-light-theme((
  color: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-red-light {
    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);
    // Apply the theme to the user components
    @include components-theme($theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $default-primary-palette, accent: $default-accent-palette, warn: $default-warn-palette);
    @include fuse-color-classes($palettes);

     input,
    textarea {
      color: black !important;
    }
    .mat-mdc-select-value-text {
      color: black !important;
    }
    .mat-mdc-form-field-label {
      color: black !important;
    }
}
// -----------------------------------------------------------------------------------------------------
// @ Define the light pink theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette(mat.$pink-palette, 200, 300, 400);
$default-accent-palette: mat.define-palette(mat.$pink-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$pink-palette);
// Create the Material theme object

$theme: mat.define-light-theme((
  color: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
  ),
  typography: mat.define-typography-config(),
 density: 0,
));

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-pink-light {
    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);
    // Apply the theme to the user components
    @include components-theme($theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $default-primary-palette, accent: $default-accent-palette, warn: $default-warn-palette);
    $theme: mat.define-light-theme((
  color: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

    @include fuse-color-classes($palettes);

     input,
    textarea {
      color: black !important;
    }
    .mat-mdc-select-value-text {
      color: black !important;
    }
    .mat-mdc-form-field-label {
      color: black !important;
    }
}


// -----------------------------------------------------------------------------------------------------
// @ Define the light deep-purple theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette(mat.$deep-purple-palette, 200, 300, 400);
$default-accent-palette: mat.define-palette(mat.$deep-purple-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$deep-purple-palette);
// Create the Material theme object

$theme: mat.define-light-theme((
  color: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
  ),typography: mat.define-typography-config(),
  density: 0,
));

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-deep-purple-light {
    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);
    // Apply the theme to the user components
    @include components-theme($theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $default-primary-palette, accent: $default-accent-palette, warn: $default-warn-palette);
    @include fuse-color-classes($palettes);

     input,
    textarea {
      color: black !important;
    }
    .mat-mdc-select-value-text {
      color: black !important;
    }
    .mat-mdc-form-field-label {
      color: black !important;
    }
}
// -----------------------------------------------------------------------------------------------------
// @ Define the light indigo theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette(mat.$indigo-palette, 200, 300, 400);
$default-accent-palette: mat.define-palette(mat.$indigo-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$indigo-palette);
// Create the Material theme object

$theme: mat.define-light-theme((
  color: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
  ),
  typography: mat.define-typography-config(),
 density: 0,
));

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-indigo-light {
    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);
    // Apply the theme to the user components
    @include components-theme($theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $default-primary-palette, accent: $default-accent-palette, warn: $default-warn-palette);
    @include fuse-color-classes($palettes);

     input,
    textarea {
      color: black !important;
    }
    .mat-mdc-select-value-text {
      color: black !important;
    }
    .mat-mdc-form-field-label {
      color: black !important;
    }
}


// -----------------------------------------------------------------------------------------------------
// @ Define the light blue-light theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette(mat.$light-blue-palette, 200, 300, 400);
$default-accent-palette: mat.define-palette(mat.$light-blue-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$light-blue-palette);
// Create the Material theme object

$theme: mat.define-light-theme((
  color: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
  ),
  typography: mat.define-typography-config(),
 density: 0,
));

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-light-blue-light {
    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);
    // Apply the theme to the user components
    @include components-theme($theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $default-primary-palette, accent: $default-accent-palette, warn: $default-warn-palette);
    @include fuse-color-classes($palettes);

     input,
    textarea {
      color: black !important;
    }
    .mat-mdc-select-value-text {
      color: black !important;
    }
    .mat-mdc-form-field-label {
      color: black !important;
    }
}
// -----------------------------------------------------------------------------------------------------
// @ Define the light cyan theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette(mat.$cyan-palette, 200, 300, 400);
$default-accent-palette: mat.define-palette(mat.$cyan-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$cyan-palette);
// Create the Material theme object

$theme: mat.define-light-theme((
  color: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
  ),
  typography: mat.define-typography-config(),
 density: 0,
));

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-cyan-light {
    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);
    // Apply the theme to the user components
    @include components-theme($theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $default-primary-palette, accent: $default-accent-palette, warn: $default-warn-palette);
    @include fuse-color-classes($palettes);

     input,
    textarea {
      color: black !important;
    }
    
    .mat-mdc-select-value-text {
      color: black !important;
    }
    
    .mat-mdc-form-field-label {
      color: black !important;
    }
}
// -----------------------------------------------------------------------------------------------------
// @ Define the light teal theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette(mat.$teal-palette, 200, 300, 400);
$default-accent-palette: mat.define-palette(mat.$teal-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$teal-palette);
// Create the Material theme object

$theme: mat.define-light-theme((
  color: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
  ),
  typography: mat.define-typography-config(),
 density: 0,
));

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-teal-light {
    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);
    // Apply the theme to the user components
    @include components-theme($theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $default-primary-palette, accent: $default-accent-palette, warn: $default-warn-palette);
    @include fuse-color-classes($palettes);

     input,
    textarea {
      color: black !important;
    }
    
    .mat-mdc-select-value-text {
      color: black !important;
    }
    
    .mat-mdc-form-field-label {
      color: black !important;
    }
}
// -----------------------------------------------------------------------------------------------------
// @ Define the light green theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette(mat.$green-palette, 200, 300, 400);
$default-accent-palette: mat.define-palette(mat.$green-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$green-palette);
// Create the Material theme object

$theme: mat.define-light-theme((
  color: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
  ),
  typography: mat.define-typography-config(),
 density: 0,
));

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-green-light {
    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);
    // Apply the theme to the user components
    @include components-theme($theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $default-primary-palette, accent: $default-accent-palette, warn: $default-warn-palette);
    @include fuse-color-classes($palettes);

     input,
    textarea {
      color: black !important;
    }
    
    .mat-mdc-select-value-text {
      color: black !important;
    }
    
    .mat-mdc-form-field-label {
      color: black !important;
    }
}
// -----------------------------------------------------------------------------------------------------
// @ Define the light lime theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette(mat.$lime-palette, 200, 300, 400);
$default-accent-palette: mat.define-palette(mat.$lime-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$lime-palette);
// Create the Material theme object

$theme: mat.define-light-theme((
  color: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
  ),
  typography: mat.define-typography-config(),
 density: 0,
));

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-lime-light {
    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);
    // Apply the theme to the user components
    @include components-theme($theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $default-primary-palette, accent: $default-accent-palette, warn: $default-warn-palette);
    @include fuse-color-classes($palettes);

     input,
    textarea {
      color: black !important;
    }
    
    .mat-mdc-select-value-text {
      color: black !important;
    }
    
    .mat-mdc-form-field-label {
      color: black !important;
    }
}

// -----------------------------------------------------------------------------------------------------
// @ Define the light orange theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette(mat.$orange-palette, 200, 300, 400);
$default-accent-palette: mat.define-palette(mat.$orange-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$orange-palette);
// Create the Material theme object

$theme: mat.define-light-theme((
  color: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
  ),
  typography: mat.define-typography-config(),
 density: 0,
));

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-orange-light {
    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);
    // Apply the theme to the user components
    @include components-theme($theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $default-primary-palette, accent: $default-accent-palette, warn: $default-warn-palette);
    @include fuse-color-classes($palettes);

     input,
    textarea {
      color: black !important;
    }
    
    .mat-mdc-select-value-text {
      color: black !important;
    }
    
    .mat-mdc-form-field-label {
      color: black !important;
    }
}
// -----------------------------------------------------------------------------------------------------
// @ Define the light deep-orange theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette(mat.$deep-orange-palette, 200, 300, 400);
$default-accent-palette: mat.define-palette(mat.$deep-orange-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$deep-orange-palette);
// Create the Material theme object

$theme: mat.define-light-theme((
  color: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
  ),
  typography: mat.define-typography-config(),
 density: 0,
));

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-deep-orange-light {
    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);
    // Apply the theme to the user components
    @include components-theme($theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $default-primary-palette, accent: $default-accent-palette, warn: $default-warn-palette);
    @include fuse-color-classes($palettes);

     input,
    textarea {
      color: black !important;
    }
    
    .mat-mdc-select-value-text {
      color: black !important;
    }
    
    .mat-mdc-form-field-label {
      color: black !important;
    }
}
// -----------------------------------------------------------------------------------------------------
// @ Define the light brown theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette(mat.$brown-palette, 200, 300, 400);
$default-accent-palette: mat.define-palette(mat.$brown-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$brown-palette);
// Create the Material theme object

$theme: mat.define-light-theme((
  color: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
  ),
  typography: mat.define-typography-config(),
 density: 0,
));

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-brown-light {
    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);
    // Apply the theme to the user components
    @include components-theme($theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $default-primary-palette, accent: $default-accent-palette, warn: $default-warn-palette);
    @include fuse-color-classes($palettes);

     input,
    textarea {
      color: black !important;
    }
    
    .mat-mdc-select-value-text {
      color: black !important;
    }
    
    .mat-mdc-form-field-label {
      color: black !important;
    }
}
// -----------------------------------------------------------------------------------------------------
// @ Define the light grey theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette(mat.$grey-palette, 200, 300, 400);
$default-accent-palette: mat.define-palette(mat.$grey-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$grey-palette);
// Create the Material theme object

$theme: mat.define-light-theme((
  color: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
  ),
  typography: mat.define-typography-config(),
 density: 0,
));

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-grey-light {
    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);
    // Apply the theme to the user components
    @include components-theme($theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $default-primary-palette, accent: $default-accent-palette, warn: $default-warn-palette);
    @include fuse-color-classes($palettes);

     input,
    textarea {
      color: black !important;
    }
    
    .mat-mdc-select-value-text {
      color: black !important;
    }
    
    .mat-mdc-form-field-label {
      color: black !important;
    }
}
// -----------------------------------------------------------------------------------------------------
// @ Define the light blue-grey theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette(mat.$blue-grey-palette,200, 300, 400);
$default-accent-palette: mat.define-palette(mat.$blue-grey-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$blue-grey-palette);
// Create the Material theme object

$theme: mat.define-light-theme((
  color: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
  ),
  typography: mat.define-typography-config(),
 density: 0,
));

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-blue-grey-light {
    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);
    // Apply the theme to the user components
    @include components-theme($theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $default-primary-palette, accent: $default-accent-palette, warn: $default-warn-palette);
    @include fuse-color-classes($palettes);

     input,
    textarea {
      color: black !important;
    }
    
    .mat-mdc-select-value-text {
      color: black !important;
    }
    
    .mat-mdc-form-field-label {
      color: black !important;
    }
}


// -----------------------------------------------------------------------------------------------------
// @ Define a yellow light theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$yellow-light-theme-primary-palette: mat.define-palette($fuse-navy, 600, 400, 700);
$yellow-light-theme-accent-palette: mat.define-palette(mat.$yellow-palette, 600, 400, 700);
$yellow-light-theme-warn-palette: mat.define-palette(mat.$red-palette);
// Create the Material theme object
$yellow-light-theme: mat.define-light-theme((
  color:(
    primary: $yellow-light-theme-primary-palette,
    accent: $yellow-light-theme-accent-palette,
    warn: $yellow-light-theme-warn-palette
  ),
  typography: mat.define-typography-config(),
  density: 0,
  ));
// Add ".theme-yellow-light" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-yellow-light {
    // Generate the Angular Material theme
    @include mat.all-component-themes($yellow-light-theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($yellow-light-theme);
    // Apply the theme to the user components
    @include components-theme($yellow-light-theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $yellow-light-theme-primary-palette, accent: $yellow-light-theme-accent-palette, warn: $yellow-light-theme-warn-palette);
    @include fuse-color-classes($palettes);

    input,
    textarea {
      color: black !important;
    }
    
    .mat-mdc-select-value-text {
      color: black !important;
    }
    
    .mat-mdc-form-field-label {
      color: black !important;
    }
    .bubble.time{
      color: black !important;
    }
}

// -----------------------------------------------------------------------------------------------------
// @ Define a blue-gray dark theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$blue-gray-dark-theme-primary-palette: mat.define-palette(mat.$blue-palette);
$blue-gray-dark-theme-accent-palette: mat.define-palette(mat.$blue-gray-palette);
$blue-gray-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);
// Create the Material theme object
$blue-gray-dark-theme: mat.define-dark-theme($blue-gray-dark-theme-primary-palette, $blue-gray-dark-theme-accent-palette, $blue-gray-dark-theme-warn-palette);
// Add ".theme-blue-gray-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-blue-gray-dark {
    // Generate the Angular Material theme
    @include mat.all-component-themes($blue-gray-dark-theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($blue-gray-dark-theme);
    // Apply the theme to the user components
    @include components-theme($blue-gray-dark-theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $blue-gray-dark-theme-primary-palette, accent: $blue-gray-dark-theme-accent-palette, warn: $blue-gray-dark-theme-warn-palette);
    @include fuse-color-classes($palettes);

    input,
    textarea {
      color: white !important;
      &.tox-textarea{
        color: black !important;
      }
    }
    
    .mat-mdc-select-value-text {
      color: white !important;
    }
    
    .mat-mdc-form-field-label {
      color: white !important;
    }
}

// -----------------------------------------------------------------------------------------------------
// @ Define a pink dark theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);
// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme((
  color: (
    primary: $pink-dark-theme-primary-palette,
    accent: $pink-dark-theme-accent-palette,
    warn: $pink-dark-theme-warn-palette
  ),  
  typography: mat.define-typography-config(),
  density: 0,
));
// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-pink-dark {
    // Generate the Angular Material theme
    @include mat.all-component-themes($pink-dark-theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($pink-dark-theme);
    // Apply the theme to the user components
    @include components-theme($pink-dark-theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $pink-dark-theme-primary-palette, accent: $pink-dark-theme-accent-palette, warn: $pink-dark-theme-warn-palette);
    @include fuse-color-classes($palettes);

    input,
    textarea {
      color: white !important;
      &.tox-textarea{
        color: black !important;
      }
    }
    
    .mat-mdc-select-value-text {
      color: white !important;
    }
    
    .mat-mdc-form-field-label {
      color: white !important;
    }
}
// -----------------------------------------------------------------------------------------------------
// @ Define a yellow dark theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$yellow-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$yellow-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$lime-palette);
// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme((
  color: (
    primary: $pink-dark-theme-primary-palette,
    accent: $pink-dark-theme-accent-palette,
    warn: $pink-dark-theme-warn-palette
  ),  
  typography: mat.define-typography-config(),
  density: 0,
));
// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-yellow-dark {
    // Generate the Angular Material theme
    @include mat.all-component-themes($pink-dark-theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($pink-dark-theme);
    // Apply the theme to the user components
    @include components-theme($pink-dark-theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $pink-dark-theme-primary-palette, accent: $pink-dark-theme-accent-palette, warn: $pink-dark-theme-warn-palette);
    @include fuse-color-classes($palettes);

    input,
    textarea {
      color: white !important;
      &.tox-textarea{
        color: black !important;
      }
    }
    
    .mat-mdc-select-value-text {
      color: white !important;
    }
    
    .mat-mdc-form-field-label {
      color: white !important;
    }
}
// -----------------------------------------------------------------------------------------------------
// @ Define a deep-purple dark theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$deep-purple-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$deep-purple-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$deep-purple-palette);
// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme((
  color : (
    primary: $pink-dark-theme-primary-palette,
    accent: $pink-dark-theme-accent-palette,
    warn: $pink-dark-theme-warn-palette
  ),
  typography: mat.define-typography-config(),
  density: 0,
  ));
// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-deep-purple-dark {
    // Generate the Angular Material theme
    @include mat.all-component-themes($pink-dark-theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($pink-dark-theme);
    // Apply the theme to the user components
    @include components-theme($pink-dark-theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $pink-dark-theme-primary-palette, accent: $pink-dark-theme-accent-palette, warn: $pink-dark-theme-warn-palette);
    @include fuse-color-classes($palettes);

    input,
    textarea {
      color: white !important;
      &.tox-textarea{
        color: black !important;
      }
    }
    
    .mat-mdc-select-value-text {
      color: white !important;
    }
    
    .mat-mdc-form-field-label {
      color: white !important;
    }
}
// -----------------------------------------------------------------------------------------------------
// @ Define a red dark theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$red-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$red-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);
// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme((
  color:(
    primary: $pink-dark-theme-primary-palette,
    accent: $pink-dark-theme-accent-palette,
    warn: $pink-dark-theme-warn-palette
  ),
  typography: mat.define-typography-config(),
  density: 0,
  ));  
// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-red-dark {
    // Generate the Angular Material theme
    @include mat.all-component-themes($pink-dark-theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($pink-dark-theme);
    // Apply the theme to the user components
    @include components-theme($pink-dark-theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $pink-dark-theme-primary-palette, accent: $pink-dark-theme-accent-palette, warn: $pink-dark-theme-warn-palette);
    @include fuse-color-classes($palettes);

    input,
    textarea {
      color: white !important;
      &.tox-textarea{
        color: black !important;
      }
    }
    
    .mat-mdc-select-value-text {
      color: white !important;
    }
    
    .mat-mdc-form-field-label {
      color: white !important;
    }
}
// -----------------------------------------------------------------------------------------------------
// @ Define a deep-purple dark theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$deep-purple-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$deep-purple-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$deep-purple-palette);
// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme((
  color: (
    primary: $pink-dark-theme-primary-palette,
    accent: $pink-dark-theme-accent-palette,
    warn: $pink-dark-theme-warn-palette
  ),
  typography: mat.define-typography-config(),
  density: 0,
  ));
// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-deep-purple-dark {
    // Generate the Angular Material theme
    @include mat.all-component-themes($pink-dark-theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($pink-dark-theme);
    // Apply the theme to the user components
    @include components-theme($pink-dark-theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $pink-dark-theme-primary-palette, accent: $pink-dark-theme-accent-palette, warn: $pink-dark-theme-warn-palette);
    @include fuse-color-classes($palettes);

    input,
    textarea {
      color: white !important;
      &.tox-textarea{
        color: black !important;
      }
    }
    
    .mat-mdc-select-value-text {
      color: white !important;
    }
    
    .mat-mdc-form-field-label {
      color: white !important;
    }
}
// -----------------------------------------------------------------------------------------------------
// @ Define a indigo dark theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$indigo-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$indigo-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$indigo-palette);
// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme((
  color:(
    primary: $pink-dark-theme-primary-palette,
    accent: $pink-dark-theme-accent-palette,
    warn: $pink-dark-theme-warn-palette
  ),
  typography: mat.define-typography-config(),
  density: 0,
  ));
// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-indigo-dark {
    // Generate the Angular Material theme
    @include mat.all-component-themes($pink-dark-theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($pink-dark-theme);
    // Apply the theme to the user components
    @include components-theme($pink-dark-theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $pink-dark-theme-primary-palette, accent: $pink-dark-theme-accent-palette, warn: $pink-dark-theme-warn-palette);
    @include fuse-color-classes($palettes);

    input,
    textarea {
      color: white !important;
      &.tox-textarea{
        color: black !important;
      }
    }
    
    .mat-mdc-select-value-text {
      color: white !important;
    }
    
    .mat-mdc-form-field-label {
      color: white !important;
    }
    
    // $foreground: map-get($theme, foreground);
    // $background: map-get($theme, background);
        
    // .mat-card {
    //   color : mat.get-color-from-palette($foreground, text);
    //   background-color: mat.get-color-from-palette($background, card);
    // }
    
    // $foreground: map-get($theme, foreground);
    // $background: map-get($theme, background);
        
    // .mat-card {
    //   color : mat.get-color-from-palette($foreground, text);
    //   background-color: mat.get-color-from-palette($background, card);
    // }
}

// -----------------------------------------------------------------------------------------------------
// @ Define a cyan dark theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$cyan-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$cyan-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$cyan-palette);
// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme((
  color:(
    primary: $pink-dark-theme-primary-palette,
    accent: $pink-dark-theme-accent-palette,
    warn: $pink-dark-theme-warn-palette
  ),
  typography: mat.define-typography-config(),
  density: 0,
  ));
// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-cyan-dark {
    // Generate the Angular Material theme
    @include mat.all-component-themes($pink-dark-theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($pink-dark-theme);
    // Apply the theme to the user components
    @include components-theme($pink-dark-theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $pink-dark-theme-primary-palette, accent: $pink-dark-theme-accent-palette, warn: $pink-dark-theme-warn-palette);
    @include fuse-color-classes($palettes);

    input,
    textarea {
      color: white !important;
      &.tox-textarea{
        color: black !important;
      }
      &.fr-code{
        color: black !important;
      }
      &.tox-textarea{
        color: black !important;
      }
    }
    
    .mat-mdc-select-value-text {
      color: white !important;
    }
    
    .mat-mdc-form-field-label {
      color: white !important;
    }
}
// -----------------------------------------------------------------------------------------------------
// @ Define a teal dark theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$teal-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$teal-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$teal-palette);
// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme((
  color:(
    primary: $pink-dark-theme-primary-palette,
    accent: $pink-dark-theme-accent-palette,
    warn: $pink-dark-theme-warn-palette
  ),
  typography: mat.define-typography-config(),
  density: 0,
  ));
// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-teal-dark {
    // Generate the Angular Material theme
    @include mat.all-component-themes($pink-dark-theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($pink-dark-theme);
    // Apply the theme to the user components
    @include components-theme($pink-dark-theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $pink-dark-theme-primary-palette, accent: $pink-dark-theme-accent-palette, warn: $pink-dark-theme-warn-palette);
    @include fuse-color-classes($palettes);

    input,
    textarea {
      color: white !important;
      &.tox-textarea{
        color: black !important;
      }
    }
    
    .mat-mdc-select-value-text {
      color: white !important;
    }
    
    .mat-mdc-form-field-label {
      color: white !important;
    }
}
// -----------------------------------------------------------------------------------------------------
// @ Define a green dark theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$green-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$green-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$green-palette);
// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme((
  color:(
    primary: $pink-dark-theme-primary-palette,
    accent: $pink-dark-theme-accent-palette,
    warn: $pink-dark-theme-warn-palette
  ),
  typography: mat.define-typography-config(),
  density: 0,
  ));
// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-green-dark {
    // Generate the Angular Material theme
    @include mat.all-component-themes($pink-dark-theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($pink-dark-theme);
    // Apply the theme to the user components
    @include components-theme($pink-dark-theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $pink-dark-theme-primary-palette, accent: $pink-dark-theme-accent-palette, warn: $pink-dark-theme-warn-palette);
    @include fuse-color-classes($palettes);

    input,
    textarea {
      color: white !important;
      &.tox-textarea{
        color: black !important;
      }
    }
    
    .mat-mdc-select-value-text {
      color: white !important;
    }
    
    .mat-mdc-form-field-label {
      color: white !important;
    }
}
// -----------------------------------------------------------------------------------------------------
// @ Define a lime dark theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$lime-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$lime-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$lime-palette);
// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme((
  color:(
    primary: $pink-dark-theme-primary-palette,
    accent: $pink-dark-theme-accent-palette,
    warn: $pink-dark-theme-warn-palette
  ),
  typography: mat.define-typography-config(),
  density: 0,
  ));
// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-lime-dark {
    // Generate the Angular Material theme
    @include mat.all-component-themes($pink-dark-theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($pink-dark-theme);
    // Apply the theme to the user components
    @include components-theme($pink-dark-theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $pink-dark-theme-primary-palette, accent: $pink-dark-theme-accent-palette, warn: $pink-dark-theme-warn-palette);
    @include fuse-color-classes($palettes);

    input,
    textarea {
      color: white !important;
      &.tox-textarea{
        color: black !important;
      }
      &.fr-code{
        color: black !important;
      }
    }
    
    .mat-mdc-select-value-text {
      color: white !important;
    }
    
    .mat-mdc-form-field-label {
      color: white !important;
    }
}
// -----------------------------------------------------------------------------------------------------
// @ Define a orange dark theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$orange-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$orange-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$orange-palette);
// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme((
  color:(
    primary: $pink-dark-theme-primary-palette,
    accent: $pink-dark-theme-accent-palette,
    warn: $pink-dark-theme-warn-palette
  ),
  typography: mat.define-typography-config(),
  density: 0,
  )); 
// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-orange-dark {
    // Generate the Angular Material theme
    @include mat.all-component-themes($pink-dark-theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($pink-dark-theme);
    // Apply the theme to the user components
    @include components-theme($pink-dark-theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $pink-dark-theme-primary-palette, accent: $pink-dark-theme-accent-palette, warn: $pink-dark-theme-warn-palette);
    @include fuse-color-classes($palettes);

    input,
    textarea {
      color: white !important;
      &.tox-textarea{
        color: black !important;
      }
    }
    
    .mat-mdc-select-value-text {
      color: white !important;
    }
    
    .mat-mdc-form-field-label {
      color: white !important;
    }
}
// -----------------------------------------------------------------------------------------------------
// @ Define a deep-orange dark theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$deep-orange-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$deep-orange-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$deep-orange-palette);
// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme((
  color:(
    primary: $pink-dark-theme-primary-palette,
    accent: $pink-dark-theme-accent-palette,
    warn: $pink-dark-theme-warn-palette
  ),
  typography: mat.define-typography-config(),
  density: 0,
  ));
// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-deep-orange-dark {
    // Generate the Angular Material theme
    @include mat.all-component-themes($pink-dark-theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($pink-dark-theme);
    // Apply the theme to the user components
    @include components-theme($pink-dark-theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $pink-dark-theme-primary-palette, accent: $pink-dark-theme-accent-palette, warn: $pink-dark-theme-warn-palette);
    @include fuse-color-classes($palettes);

    input,
    textarea {
      color: white !important;
      &.tox-textarea{
        color: black !important;
      }
    }
    
    .mat-mdc-select-value-text {
      color: white !important;
    }
    
    .mat-mdc-form-field-label {
      color: white !important;
    }
}
// -----------------------------------------------------------------------------------------------------
// @ Define a brown dark theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$brown-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$brown-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$brown-palette);
// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme((
  color:(
    primary: $pink-dark-theme-primary-palette,
    accent: $pink-dark-theme-accent-palette,
    warn: $pink-dark-theme-warn-palette
  ),
  typography: mat.define-typography-config(),
  density: 0,
  ));
// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-brown-dark {
    // Generate the Angular Material theme
    @include mat.all-component-themes($pink-dark-theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($pink-dark-theme);
    // Apply the theme to the user components
    @include components-theme($pink-dark-theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $pink-dark-theme-primary-palette, accent: $pink-dark-theme-accent-palette, warn: $pink-dark-theme-warn-palette);
    @include fuse-color-classes($palettes);

    input,
    textarea {
      color: white !important;
      &.tox-textarea{
        color: black !important;
      }
      &.fr-code{
        color: black !important;
      }
    }
    
    .mat-mdc-select-value-text {
      color: white !important;
    }
    
    .mat-mdc-form-field-label {
      color: white !important;
    }
    
    // $foreground: map-get($theme, foreground);
    // $background: map-get($theme, background);
        
    // .mat-card {
    //   color : mat.get-color-from-palette($foreground, text);
    //   background-color: mat.get-color-from-palette($background, card);
    // }
}
// -----------------------------------------------------------------------------------------------------
// @ Define a grey dark theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$grey-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$grey-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$grey-palette);
// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme((
  color:(
    primary: $pink-dark-theme-primary-palette,
    accent: $pink-dark-theme-accent-palette,
    warn: $pink-dark-theme-warn-palette
  ),
  typography: mat.define-typography-config(),
  density: 0,
  ));
// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-grey-dark {
    // Generate the Angular Material theme
    @include mat.all-component-themes($pink-dark-theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($pink-dark-theme);
    // Apply the theme to the user components
    @include components-theme($pink-dark-theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $pink-dark-theme-primary-palette, accent: $pink-dark-theme-accent-palette, warn: $pink-dark-theme-warn-palette);
    @include fuse-color-classes($palettes);

    input,
    textarea {
      color: white !important;
      &.tox-textarea{
        color: black !important;
      }
      &.fr-code{
        color: black !important;
      }
    }
    
    .mat-mdc-select-value-text {
      color: white !important;
    }
    
    .mat-mdc-form-field-label {
      color: white !important;
    }
}
// -----------------------------------------------------------------------------------------------------
// @ Define a blue-grey dark theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$blue-grey-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$blue-grey-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$blue-grey-palette);
// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme((
  color:(
    primary: $pink-dark-theme-primary-palette,
    accent: $pink-dark-theme-accent-palette,
    warn: $pink-dark-theme-warn-palette
  ),
  typography: mat.define-typography-config(),
  density: 0,
  ));
// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-blue-grey-dark {
    // Generate the Angular Material theme
    @include mat.all-component-themes($pink-dark-theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($pink-dark-theme);
    // Apply the theme to the user components
    @include components-theme($pink-dark-theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (primary: $pink-dark-theme-primary-palette, accent: $pink-dark-theme-accent-palette, warn: $pink-dark-theme-warn-palette);
    @include fuse-color-classes($palettes);

    input,
    textarea {
      color: white !important;
      &.tox-textarea{
        color: black !important;
      }
    }
    
    .mat-mdc-select-value-text {
      color: white !important;
    }
    
    .mat-mdc-form-field-label {
      color: white !important;
    }
}

@mixin ng-select-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $isdark: map-get($theme, is-dark);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);
    $highlight-color: if($isdark, mat.get-color-from-palette($foreground, text), mat.get-color-from-palette($primary));
    .ng-select,
    .ng-select-container,
    .ng-input>input {
        padding: 0;
        color: mat.get-color-from-palette($foreground, text) !important;
        font: inherit;
        font-family: inherit;
    }
    .ng-placeholder {
        display: none;
    }
    .ng-clear-wrapper,
    .ng-arrow-wrapper {
        height: 1em;
        color: mat.get-color-from-palette($foreground, text, .4);
    }
    .ng-clear-wrapper:hover,
    .ng-arrow-wrapper:hover {
        color: mat.get-color-from-palette($foreground, text);
    }
    .ng-select .ng-arrow-wrapper .ng-arrow {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid;
        height: 7px !important;
    }
    // .ng-select.ng-select-single .ng-select-container .ng-value-container {
    //     height: 1em;
    // }
    .ng-select.ng-select-multiple {
        .ng-value {
            // WIP
            color: mat.get-color-from-palette($primary, default-contrast);
            background: mat.get-color-from-palette($primary);
            padding: 2px 8px;
            border-radius: 12px;
            margin: 0 4px 2px 0;
            .ng-value-label {
                margin-left: 8px;
            }
        }
    }
    .ng-dropdown-panel {
        @include mat.elevation(4);
        background: mat.get-color-from-palette($background, card);
        color: mat.get-color-from-palette($foreground, text) !important;
        .mat-mdc-option.ng-option-selected:not(.ng-option-marked):not(:hover) {
            background: mat.get-color-from-palette($background, card);
            &:not(.ng-option-disabled) {
                color: mat.get-color-from-palette($foreground, text);
            }
        }
        // left: 0;
        &.ng-select-bottom {
            top: calc(100% + .9em);
        }
        &.ng-select-top {
            bottom: calc(100% + 1.25em);
        }
        &.multiple {
            .ng-option {
                &.selected {
                    background: mat.get-color-from-palette($background, card);
                }
                &.marked {
                    background: mat.get-color-from-palette($foreground, text, .04);
                }
            }
        }
        .ng-dropdown-header {
            border-bottom: 1px solid mat.get-color-from-palette($foreground, text, .12);
            padding: 0 16px;
            line-height: 3em;
            min-height: 3em;
        }
        .ng-dropdown-footer {
            border-top: 1px solid mat.get-color-from-palette($foreground, text, .12);
            padding: 0 16px;
            line-height: 3em;
            min-height: 3em;
        }
        .ng-dropdown-panel-items {
            .ng-optgroup {
                user-select: none;
                cursor: pointer;
                line-height: 3em;
                height: 3em;
                padding: 0 16px;
                color: mat.get-color-from-palette($foreground, text);
                font-weight: 500;
                &.ng-option-marked {
                    background: mat.get-color-from-palette($foreground, text, .04);
                }
                &.ng-option-disabled {
                    cursor: default;
                }
                &.ng-option-selected {
                    background: mat.get-color-from-palette($foreground, text, .12);
                    color: $highlight-color;
                }
            }
            .ng-option {
                line-height: 3em;
                min-height: 3em;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0 16px;
                text-decoration: none;
                position: relative;
                color: mat.get-color-from-palette($foreground, text, .87);
                text-align: left;
                &.ng-option-marked {
                    background: mat.get-color-from-palette($foreground, text, .04);
                    color: mat.get-color-from-palette($foreground, text, .87);
                }
                &.ng-option-selected {
                    background: mat.get-color-from-palette($foreground, text, .12);
                    color: $highlight-color;
                }
                &.ng-option-disabled {
                    color: mat.get-color-from-palette($foreground, text, 0.38);
                }
                &.ng-option-child {
                    padding-left: 32px;
                }
                .ng-tag-label {
                    padding-right: 5px;
                    font-size: 80%;
                    font-weight: 400;
                    color: mat.get-color-from-palette($foreground, text, 0.38);
                }
            }
        }
    }
}
@include ng-select-theme($theme);
// .fr-wrapper > div:first-child { display: none !important; }
.fr-box{
  .second-toolbar{
    #logo{
      display: none !important;
    }
  }
}
.fr-view{
  #pbf{
    display: none !important;
  }
}
.tox-statusbar__branding{
  display: none !important;;
}
.hidden{
  display: none !important;;
}
.cursor{
  cursor: pointer;
}
//Tabs menu: drag left and right without using arrows issue#169
// .mat-mdc-tab-list {
//   transform: unset !important;
//   overflow-x: auto !important;
// }

.tox .tox-textarea,
.tox .tox-textfield,
.tox .tox-toolbar-textfield {
    color: #222f3e !important;
}
@mixin stripe-subscriptions-theme($theme) {

    $foreground: map-get($theme, foreground);

    app-stripe-subscriptions {

        #price-tables {

            .faq {

                .item {

                    .answer {
                        color: map-get($foreground, secondary-text);
                    }
                }
            }
        }
    }
}
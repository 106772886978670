
// List Page 
@use '@angular/material' as mat;
#list-page {
  .top-bg {
      @include media-breakpoint("lt-md") {
          height: 256px;
      }
  }

  > .center {
      > .header {
          .search-wrapper {
              width: 100%;
              max-width: 480px;
              border-radius: 28px;
              overflow: hidden;
              @include mat.elevation(1);

              @include media-breakpoint("xs") {
                  width: 100%;
              }

              .search {
                  width: 100%;
                  height: 48px;
                  line-height: 48px;
                  padding: 0 18px;

                  input {
                      width: 100%;
                      height: 48px;
                      min-height: 48px;
                      max-height: 48px;
                      padding: 0 16px;
                      border: none;
                      outline: none;
                  }
              }
          }

          @include media-breakpoint("lt-md") {
              padding: 8px 0;
              height: 192px !important;
              min-height: 192px !important;
              max-height: 192px !important;
          }
      }
  }
}

// Data Table
.data-table {
  flex: 1 1 auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  .mat-mdc-header-row {
    min-height: 50px;
  }

  .data-row {
    position: relative;
    cursor: pointer;
    height: 60px;
  }

  .mat-mdc-cell {
    min-width: 0;
    display: flex;
    align-items: center;
  }
  
  .mat-column-action {
    flex: 0 1 80px !important;
  }

  .item-image {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

//added
.products-table{
  .mat-mdc-row {
    height: unset !important;
  }
  .mat-column-action {
    flex: 0 1 80px !important;
  }
}

.del-btn {
  color: red !important;

  .mat-icon {
    color: red !important;
  }
}

.pointer {
  cursor: pointer;
}

//added
mat-form-field {
  > .mat-mdc-text-field-wrapper {
    flex:unset !important;
    // padding-right: inherit !important;
  }
  > .mat-mdc-form-field-flex{
    padding: 0rem 1rem !important;
  }
} 

.mat-mdc-raised-button>.mat-icon, .mat-mdc-outlined-button>.mat-icon{
  margin-left: 2px !important;
  margin-right: 2px !important;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content{
  line-height: 36px !important;
}

.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}
.mat-mdc-tab-link-container, .mat-mdc-tab-header{
  border-bottom: 1px solid lightgray;
}

//add some padding to select input having icon suffix
.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  padding-right: 0.5rem !important;
}

// fix pagination select 
.mat-mdc-paginator-page-size{
  margin-right: 0px !important;
}

.mat-mdc-paginator-range-label{
  margin: 0px 4px!important;
}

.mat-mdc-paginator-page-size-select {
  width: auto !important;
}

// increase font weight of tab 
.mat-mdc-tab-link, .mdc-tab__text-label{
  font-weight: 600 !important;
  letter-spacing: normal !important;
}

.mat-mdc-raised-button > .mat-icon,
.mat-mdc-button > .mat-icon {
  font-size: 2.125rem !important;
}

//fixed text field for more visibility
// .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label--float-above,
// .mdc-text-field--outlined.mdc-text-field--disabled .mdc-floating-label, .mdc-text-field--outlined.mdc-text-field--disabled .mdc-floating-label--float-above{
//   color: unset !important;
// }

.mat-mdc-menu-item{
  min-height: 40px !important;
}

.mat-mdc-menu-item, .mat-mdc-menu-item:visited, .mat-mdc-menu-item:link {
  color: inherit !important; 
  text-decoration: none !important; 
}
.mat-mdc-menu-panel{
  max-width: fit-content !important;
}
.mat-mdc-form-field{
  margin: .25em 0 !important;
}
@mixin user-faqs-theme($theme) {
    user-faqs {
        .header {
            .search {
                background: white;
                color: rgba(0, 0, 0, 0.54);
                input {
                    background: white;
                    color: rgba(0, 0, 0, 0.87);
                }
            }
        }
    }
}
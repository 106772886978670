@mixin toolbar-theme($theme) {

    $foreground: map-get($theme, foreground);

    toolbar {

        .toolbar-separator {
            background: map-get($foreground, divider);
        }
    }
}

.custom-badge-button {
    .mat-badge-content {
        top: 1% !important;
        right: 5% !important;
        min-width: 20px !important;
        min-height: 20px !important;
        max-width: 25px !important;
        height: auto !important;
        line-height: 20px !important;
        border-radius: 10px !important;
        font-size: 12px !important;
        font-weight: 500 !important;
    }
}
@mixin quick-panel-theme($theme) {
    $accent: map-get($theme, accent);

    quick-panel {
         .timeline-sidebar {
                height: 75vh;
                overflow-y: scroll;
                .latest-activity {

                    .content {
                        
                        .activities {

                            .activity {

                                .username {
                                    // color: map-get($accent, default);
                                }
                                                              
                            }
                        }
                    }
                }
            }
        }
    
}

.notification-button {
    .mat-badge-content {
        top: 1% !important;
        min-width: 20px !important;
        min-height: 20px !important;
        max-width: 25px !important;
        height: auto !important;
        line-height: 20px !important;
        border-radius: 10px !important;
        font-size: 12px !important;
        font-weight: 500 !important;
    }
}